
import type { PropType } from 'vue'
import {
  defineComponent,
  toRefs,
  computed
} from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  name: 'ContactsBlockedUnblockModal',
  components: {
    TmButton,
    TmModal,
  },
  props: {
    name: {
      type: Array as PropType<string[]>,
      default: () => [''],
    },
  },
  setup(props) {
    const { name } = toRefs(props)

    const message = computed(() => (name.value.length === 1)
      ? 'You will be able to exchange messages with this contact once unblocked.'
      : 'You will be able to exchange messages with these contacts once unblocked.')

    return {
      message,
    }
  },
})
